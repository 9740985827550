import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './lib/App';
import reportWebVitals from './reportWebVitals';

import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from '@firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyDgVvE2c5770E4kipfeLIT5JaXdqWmyN9A",
  authDomain: "card-app-50619.firebaseapp.com",
  projectId: "card-app-50619",
  storageBucket: "card-app-50619.firebasestorage.app",
  messagingSenderId: "402619190219",
  appId: "1:402619190219:web:6ede31d9666ad72dd3c44e"
};

const app = initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
