import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  const [type, setType] = useState('password')
  const [result, setResult] = useState('')
  const [showExtra, setShowExtra] = useState(false)
  const templateRef = useRef<HTMLInputElement>(null)
  const clientEmailRef = useRef<HTMLInputElement>(null)
  const privateKeyRef = useRef<HTMLTextAreaElement>(null)
  const projectIdRef = useRef<HTMLInputElement>(null)
  const fileRef = useRef<HTMLInputElement>(null)
  const tokensRef = useRef<HTMLInputElement>(null)
  const topicRef = useRef<HTMLInputElement>(null)
  const titleRef = useRef<HTMLInputElement>(null)
  const bodyRef = useRef<HTMLInputElement>(null)
  const imageRef = useRef<HTMLInputElement>(null)
  const dataRef = useRef<HTMLTextAreaElement>(null)
  const androidRef = useRef<HTMLTextAreaElement>(null)
  const apnRef = useRef<HTMLTextAreaElement>(null)
  const webPushRef = useRef<HTMLTextAreaElement>(null)

  const urlSearch = new URLSearchParams(window.location.search)
  const templateName = urlSearch.get('template') || 'default'
  const keys = []
  for (var i = 0; i < localStorage.length; i++) {
    keys.push(localStorage.key(i))
  }


  useEffect(() => {
    const data = localStorage.getItem(templateName)
    if (!data) return
    try {
      const json = JSON.parse(data)
      templateRef.current!.value = templateName ?? ''
      clientEmailRef.current!.value = json?.credential?.clientEmail ?? ''
      projectIdRef.current!.value = json?.credential?.projectId ?? ''
      privateKeyRef.current!.value = json?.credential?.privateKey ?? ''
      tokensRef.current!.value = json?.target?.tokens?.join(',') ?? ''
      topicRef.current!.value = json?.target?.topic ?? ''
      titleRef.current!.value = json?.notification?.title ?? ''
      bodyRef.current!.value = json?.notification?.body ?? ''
      imageRef.current!.value = json?.notification?.imageUrl ?? ''
      if (json?.data) {
        dataRef.current!.value = JSON.stringify(json?.data)
      }
    } catch (e) { }
  }, [templateName || 'default'])

  function _onChangeType(event: ChangeEvent<HTMLInputElement>): void {
    setType(event.currentTarget.checked ? 'text' : 'password')
  }

  function _onFilePicked(event: ChangeEvent<HTMLInputElement>): void {
    const file = event.currentTarget.files?.item(0)
    if (!file) return
    const fileReader = new FileReader()
    fileReader.onload = async (ev: ProgressEvent<FileReader>) => {
      const text = ev.target?.result
      if (!text) return
      try {
        const json = JSON.parse(text!.toString())
        console.log(json);

        clientEmailRef.current!.value = json.client_email || json.clientEmail
        privateKeyRef.current!.value = json.private_key || json.privateKey
        projectIdRef.current!.value = json.project_id || json.projectId
      } catch (e) {
        toast('Cannot parse file')
      }
      fileRef.current!.value = ''
    }
    fileReader.readAsText(file)
  }

  function getBigData() {
    const target: any = {}
    if (topicRef.current?.value) {
      target.topic = topicRef.current.value.trim()
    }
    const token = tokensRef.current?.value
    if (token) {
      const tokens = token.split(',')
      target.tokens = tokens
    }
    const data: { [key: string]: string } = {}
    if (dataRef.current?.value?.trim()) {
      try {
        const json = JSON.parse(dataRef.current?.value?.trim())
        for (const key in json) {
          const element = json[key];
          data[key.toString()] = element.toString()
        }
      } catch (e) {
        toast(`Cannot parse data: ${e}`)
      }
    }
    const notification: any = {
      title: titleRef.current?.value?.trim(),
      body: bodyRef.current?.value?.trim(),
    }
    if (imageRef.current?.value?.trim()) {
      notification.imageUrl = imageRef.current?.value?.trim()
    }
    const result: any = {
      credential: {
        clientEmail: clientEmailRef.current?.value?.trim(),
        projectId: projectIdRef.current?.value?.trim(),
        privateKey: privateKeyRef.current?.value?.trim(),
      },
      target: target,
      notification: notification,
      data: data,
    }
    if (androidRef.current?.value?.trim()) {
      try {
        const androidJson = JSON.parse(androidRef.current.value.trim())
        result.android = androidJson
      } catch (e) { }
    }
    if (apnRef.current?.value?.trim()) {
      try {
        const androidJson = JSON.parse(apnRef.current.value.trim())
        result.apns = androidJson
      } catch (e) { }
    }
    if (webPushRef.current?.value?.trim()) {
      try {
        const androidJson = JSON.parse(webPushRef.current.value.trim())
        result.webpush = androidJson
      } catch (e) { }
    }
    return result
  }

  async function _onSendNotification(): Promise<void> {
    const data = getBigData()
    const templateName = templateRef.current?.value?.trim() || 'default'
    const encodeData = JSON.stringify(data)
    localStorage.setItem(templateName, encodeData)
    updateTemplateName(templateName)
    const result = await fetch(process.env.REACT_APP_BASE_URL + 'api/fcm-messaging', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: encodeData,
    })
    const mJson = await result.text()
    console.log(mJson);
    toast(mJson)
    setResult(mJson)
  }

  function updateTemplateName(name: string) {
    var newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?template=${name}`;
    window.history.pushState({}, '', newUrl);
  }

  function _onOptionChange(event: ChangeEvent<HTMLSelectElement>): void {
    console.log(event.currentTarget.value);
    updateTemplateName(event.target.value)
    window.location.reload()
  }

  function _onChangeShowExtra(event: ChangeEvent<HTMLInputElement>): void {
    setShowExtra(event.currentTarget.checked)
  }

  return (
    <div className='container'>
      <label htmlFor="">Templates</label>
      <select
        name={templateName}
        defaultValue={templateName}
        id="template_name"
        onChange={_onOptionChange}>
        {
          keys.map((e) => (
            <option value={e || ''} key={e}>{e}</option>
          ))
        }
      </select>
      <label>Template name</label>
      <input
        type='text'
        ref={templateRef}
        placeholder='default' />

      <h5 className='mt-16'>Authentication</h5>

      <label>Client Email</label>
      <input
        type={type}
        ref={clientEmailRef}
        placeholder='firebase-adminsdk-uw30u@....iam.gserviceaccount.com' />

      <label>Private Key</label>
      <textarea
        className={type == 'password' ? 'hide' : ''}
        ref={privateKeyRef}
        placeholder='-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkq...' />

      <label>Project Id</label>
      <input
        type={type}
        ref={projectIdRef}
        placeholder='project-id-50619' />

      <div className='d-flex align-items-center mt-16'>
        <input
          type="checkbox"
          id='show-content'
          title='Show content'
          onChange={_onChangeType}
          style={{ width: 24, height: 24 }} />
        <label htmlFor="show-content" style={{ flex: 1, margin: 0, marginLeft: 12 }}> Show content</label>
      </div>
      <div className='d-flex align-items-center mt-8'>
        <span>Private authentication file:</span>
        <input
          type="file"
          style={{ flex: 1 }}
          ref={fileRef}
          onChange={_onFilePicked} />
      </div>
      <div>We <b>don't save</b> authentication data. We use it one for send notification</div>
      <hr />
      <h5 className='mt-16'>Target</h5>
      <label>Tokens</label>
      <input
        type="text"
        ref={tokensRef}
        placeholder='f8wOBhT6S9e-vZKse9rlU8:APA91bElg7a...,f8wOBhT6S9e-vZKse9rlU8:APA91bElg7a...' />
      <label>Topic</label>
      <input
        type="text"
        ref={topicRef}
        placeholder='Topic' />
      <hr />
      <h5 className='mt-16'>Notification</h5>
      <label>Title</label>
      <input
        type="text"
        ref={titleRef}
        placeholder='Template title of notification' />
      <label>Body</label>
      <input
        type="text"
        ref={bodyRef}
        placeholder='Template body of notification' />
      <label>Image Url</label>
      <input
        type="text"
        ref={imageRef}
        placeholder='https://domain.com/notification.png' />
      <label>Data</label>
      <textarea
        rows={5}
        ref={dataRef}
        placeholder='{ "key1": "data1" }' />

      <div className='d-flex align-items-center mt-16 justify-content-between'>
        <b>Extra</b>
        <div className='d-flex'>
          <input type="checkbox" id='show-extra' style={{ width: 32, height: 24 }} onChange={_onChangeShowExtra} />
          <label htmlFor="show-extra" style={{ margin: 0 }}>Show/hide</label>
        </div>
      </div>
      <div>All key in json is camelCase. <a href="https://firebase.google.com/docs/reference/fcm/rest/v1/projects.messages?hl=vi#resource:-message">Ref document</a></div>

      <div style={{ height: showExtra ? '100%' : 0, overflow: 'clip' }}>
        <label>Android</label>
        <textarea
          rows={5}
          ref={androidRef}
          placeholder='{ "key1": "data1" }' />
        <label>Apn</label>
        <textarea
          rows={5}
          ref={apnRef}
          placeholder='{ "key1": "data1" }' />
        <label>Web Push</label>
        <textarea
          rows={5}
          ref={webPushRef}
          placeholder='{ "key1": "data1" }' />
      </div>
      <div className='mt-16'></div>
      <div>
        <b>
          {
            result
          }
        </b>
      </div>
      <hr />
      <div style={{ marginTop: 64 }}></div>
      <div className='d-flex'>
        <div className='flex-grow-1'></div>
        <button
          onClick={_onSendNotification}
          style={{ backgroundColor: 'var(--toastify-color-success)', color: 'white' }}>
          <b>Send now 🚀</b>
        </button>
      </div>
      <div style={{ height: 72 }}></div>
      <ToastContainer />
    </div>
  );
}

export default App;
